$(document).ready(function () {
    
    var forms = document.getElementsByClassName('needs-validation');
    var $form = $('.needs-validation');
    
    var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            event.stopPropagation();
            
            if (form.checkValidity() !== false) {
                    $form.find('.btn').prop('disabled', true);
                    $form.find('.btn').text('Please wait..');
                    $.post($form.attr('action'), $form.serialize(), function (result) {
                        $form.find('.btn').prop('disabled', false);
                        $form.find('.btn').text('submit');
                        var result = JSON.parse(result);
                        var type = result.type;
                        $('.response-msg').html('<div class="response ' + result.type + '">' + result.text + '</div>');

                        if (result.type == 'done') {
                            $form.find('.form-control').val('');
                        }
                    });
            }
          
        form.classList.add('was-validated');
      }, false);
    });
    // $('body').on('submit', '.email-form', function (e) {
        

    //     if(!hasError)
    //     {
    //         $form.find('.btn-send').prop('disabled', true);
    //         $form.find('.btn-send').text('Please wait..');
    //         $.post($form.attr('action'), $form.serialize(), function (result) {
    //             $form.find('.btn-send').prop('disabled', false);
    //             $form.find('.btn-send').text('submit');
    //             var result = JSON.parse(result);
    //             var type = result.type;
    //             $('.section-contact .section-btn-block').after('<div class="response ' + result.type + '">' + result.text + '</div>');

    //             if (result.type == 'done') {
    //                 $form.find('.form-control').val('');
    //             }
    //         });
    //     }    
    // });

}); 
